<template>
    <AppPaginatedGrid ref="grid" :service="service" permission="configuracoes_usuario" newPath="/users/new"
        editPath="/users/" :expander="false" subtitle="Usuário" tooltip="Cadastro Usuários"
        nomeTelaDoManual="users-list" origemHistoricoAcao="/users">
        <template #columns>
            <Column field="name" header="Nome." :sortable="true" sortField="users.name"></Column>
            <Column field="email" header="E-mail" :sortable="true" sortField="users.email"></Column>
            <Column field="phone" header="Telefone" :sortable="true" sortField="phone"></Column>
            <Column field="lastAccess" header="Último acesso" :sortable="true" sortField="lastAccess">
                <template #body="{ data }">
                    <span class="mr-2">
                        {{ data.lastAccess ? $filters.formatDate(data.lastAccess) : '' }}
                    </span>
                </template>
            </Column>
            <Column field="profile.name" header="Perfil" :sortable="true" sortField="profile.name"></Column>
        </template>
    </AppPaginatedGrid>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/users');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
